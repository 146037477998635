import React from 'react';
import Background from '../header/Background';
import '../header/Background.css';
import mainLogo from '../header/images/visitbook-logo.png';
import {useNavigate} from "react-router-dom";

const Welcome: React.FC = () => {
    const navigator = useNavigate();

    const handleClick = () => {
        navigator('/companies');
    }

    return (
        <div onClick={handleClick} onTouchStart={handleClick}>
            <Background>
                <div className="wrapper" onClick={handleClick} onTouchStart={handleClick}>
                    <div className="header-info">
                        <h1>cистема керування гостьовим доступом</h1>
                        <div className="logo">
                            <img src={mainLogo} alt="logo"/>
                            <p>Visitbook</p>
                        </div>
                    </div>
                </div>
            </Background>
        </div>
    );
};

export default Welcome;
