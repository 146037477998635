import React from 'react';
import './companies.css';
import {useAppContext} from "../appContext";
import {useNavigate} from "react-router-dom";

export default function CompanyItem({ company }) {
    const navigator = useNavigate();
    const { globalProps } = useAppContext();

    const clearVisit = () => {
        globalProps.visit = {
            name: null,
            company: null,
            email: null,
            phone: null,
            company_hash: null,
            visitor_hash: null,
            host_id: null,
            host_name: null
        }
    }

    const handleCompanyCLick = (company) => {
        globalProps.company = company;
        navigator('/main');
        clearVisit();
    }

    return (
        <button key={company.id}
             className='item-card'
             onClick={() => {handleCompanyCLick(company)} }
             onTouchStart={() => {handleCompanyCLick(company)} } >
            {company.logoOriginal && <img src={"https://app.visitbook.io/uploads/company/" + company.logoOriginal} alt={company.name} />}
        </button>
    );
}
