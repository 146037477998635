import React, { useState } from 'react';
import QrReader from "react-qr-scanner";
import axios from "axios";
import {useAppContext} from "../appContext";
import {useNavigate} from "react-router-dom";

const Scanner = () => {

    const navigate = useNavigate();
    const [result, setResult] = useState('No result');
    const [isScanning, setIsScanning] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const { globalProps } = useAppContext();

    const fetchCompanyDetails = async (visit_hash) => {
        try {
            const response = await axios({
                method: 'GET',
                url: process.env.REACT_APP_BACKEND_URL + "/apiClient/visit/company",
                params: {visit_hash: visit_hash},
            });

            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
        } catch (error) {
            console.error(error);
        }
        return null;
    };

    const handleScan = async (data) => {
        if ((isScanning && data != null) && globalProps.rulesAgreement) {
            const parts = data.text.split(',');
            if (parts.length > 1) {
                setResult(data.text);
                const visit_hash = parts[1];
                const visitor_hash = parts[0];
                setIsScanning(false);

                const companyData = await fetchCompanyDetails(visit_hash);
                if (companyData) {
                    globalProps.company = companyData;

                    globalProps.visit.visit_hash = visit_hash;
                    globalProps.visit.visitor_hash = visitor_hash;

                    globalProps.action = 'update';

                    console.log(visit_hash);
                    console.log(visitor_hash);
                }

                setIsLoading(false);

                if (companyData.settings.COMPANY_SETTING_IS_PHOTOBLE === '1') {
                    navigate('/photo');
                } else {
                    navigate('/finish');
                }
            }
        }
    };

    const handleError = (err) => {
        console.error(err);
        setIsLoading(true);
    }

    const previewStyle = {
        height: "400px",
        width: "400px",
    };

    return (
        // <div style={{marginTop: '25px'}}>
            <div style={{borderRadius: '120px', overflow: 'hidden', width: '400px', height: '400px'}}>
                <QrReader
                    delay={100}
                    style={previewStyle}
                    onError={handleError}
                    onScan={handleScan}
                />
            </div>
        // </div>

    );
};

export default Scanner;
