import React from 'react';
import "./spinner.css";
export default function Spinner() {
    return (
      <>
          <div className="loader-wrapper">
              <span className="loader"></span>
          </div>
      </>
    );
};


