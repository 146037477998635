import React, {useEffect, useState} from 'react';
import './visit.css';
import backIcon from '../visit/images/backIcon.svg';
import {useNavigate} from "react-router-dom";
import {useAppContext} from "../appContext";

interface ToggleProps {
    link: string
}

const TopMenu: React.FC<ToggleProps> = ({link = '/'}) => {
    const navigate = useNavigate();
    const { globalProps } = useAppContext();
    const [logo, setLogo] = useState('/visit/images/logo1.png');

    useEffect(() => {
        if (globalProps.company == null) {
            navigate('/companies');
        } else{
            if('logoOriginal' in globalProps.company){
                setLogo(process.env.REACT_APP_FRONTEND_URL +'/uploads/company/' + globalProps.company.logoOriginal);
            }
        }



    }, [])

    const handleClick = () => {
        navigate(link);
    }

    return (
        <>
            <div className="top-menu">
                <div className="back-btn" onClick={() => handleClick()} onTouchStart={() => handleClick()}>
                    <img src={backIcon} alt="backIcon"/>
                    <p>Назад</p>
                </div>
                <div className="top-menu__company">
                    <img className="company-logo"
                         src={logo}
                         alt=""/>
                </div>
            </div>
        </>
    );
}

export default TopMenu;
