import React from 'react';
import WebcamCapture from "../camera/photo";
import Background from "../header/Background";
import StatusMenu from "../statusmenu/StatusMenu";

const Photo = () => {

    return (
        <>
            <div className="inner-page">
                <Background>
                    <WebcamCapture />
                    <StatusMenu active={[0, 1, 2]} />
                </Background>
            </div>
        </>
    );
};

export default Photo;
