import React, {useEffect, useState} from 'react';
import Background from "../header/Background";
import './visit.css';
import StatusMenu from "../statusmenu/StatusMenu";
import TopMenu from "./TopMenu";
import HostCard from "./HostCard";
import {useAppContext} from "../appContext";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Spinner from "../spinner/spinner";

export default function Host() {
    const [name, setName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const { globalProps } = useAppContext();
    const navigator = useNavigate();

    const fetchData = async () => {
        try {
            const response = await axios({
                method: 'get',
                url: process.env.REACT_APP_BACKEND_URL + '/apiClient/user/list?company_id=' + globalProps.company.id,
                headers: {},
            });

            if (response.status >= 200 && response.status < 300) {
                setData(response.data);
                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);
            setIsLoading(true);
        }
    };

    const search = (item) => {
        const fullName = item.last_name + ' ' + item.first_name;

        if(name.length > 2){
            if (name.toLowerCase() === fullName.substring(0, name.length).toLowerCase()){
                return true;
            }
        }else if (item.show_on_kiosk == 1){
            return true;
        }

        return false;
    }

    useEffect(() => {
        if (globalProps.company == null) {
            navigator('/companies');
        }else{
            fetchData();
        }
    }, []);

    const handleClick = (item) => {
        globalProps.visit.host_id = item.id;
        globalProps.visit.host_name = item.last_name + ' ' + item.first_name;
        navigator('/main');
    }

    return (
        <>
            {isLoading ? (<Spinner />) : (
            <div className="inner-page">
                <Background>
                    <TopMenu link={'/main'} />
                    <div className="wrapper">
                        <input
                            type="search"
                            className='search'
                            id="search-host"
                            placeholder="Введіть до кого візит"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                        <div className="user-wrap host">
                            {data.map((item, key) => {
                                if (!search(item)) { return null; }

                                return (
                                <div key={item.id} className="user" onClick={() => handleClick(item)} onTouchStart={() => handleClick(item)}>
                                    <div className="user__info">
                                        <p>{item.last_name} {item.first_name}</p>
                                    </div>
                                    <div className="user__status">{item.position}</div>
                                </div>);
                            })}
                        </div>
                    </div>
                    <StatusMenu active={[0,1]} />
                </Background>
            </div>
            )}
        </>
    );
}
