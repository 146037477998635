import React from 'react';
import './StatusMenu.css';
import chooseIcon from '../statusmenu/images/chooseIcon.svg';
import userIcon from '../statusmenu/images/userIcon.svg';
import choseUserIcon from '../statusmenu/images/choseUserIcon.svg';
import printIcon from '../statusmenu/images/printIcon.svg';
interface StatusMenuProps {
    active: number[];
}

const StatusMenu: React.FC<StatusMenuProps> = (props) => {
    const { active } = props;
    const handleClick = (index: number) => {};

    return (
        <div className="status-menu">
            <div className={`status-menu__item ${active.includes(0) ? 'active' : ''}`}>
                <p>Оберіть компанію</p>
                <img src={chooseIcon} alt="Оберіть компанію" onClick={() => handleClick(0)} />
            </div>

            <div className={`status-menu__item ${active.includes(1) ? 'active' : ''}`}>
                <p>Введіть дані</p>
                <img src={userIcon} alt="Введіть дані" onClick={() => handleClick(1)} />
            </div>

            <div className={`status-menu__item ${active.includes(2) ? 'active' : ''}`}>
                <p>Зробіть фото</p>
                <img src={choseUserIcon} alt="Зробіть фото" onClick={() => handleClick(2)} />
            </div>

            <div className={`status-menu__item ${active.includes(3) ? 'active' : ''}`}>
                <p>Друк перепустки</p>
                <img src={printIcon} alt="Друк перепустки" onClick={() => handleClick(3)} />
            </div>
        </div>
    );
};

export default StatusMenu;
