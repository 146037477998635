import React from 'react';
import Background from "../header/Background";
import StatusMenu from "../statusmenu/StatusMenu";
import Rules from "../rules";
import "./scanInvite.css"
import qrImage from "./images/qr_new.svg"
import backIcon from "../visit/images/backIcon.svg";
import Scanner from "../camera/scanner";
import {useAppContext} from "../appContext";
import {useNavigate} from "react-router-dom";

export default function ScanInvite() {
    const { globalProps } = useAppContext();
    const navigation = useNavigate();
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        globalProps.rulesAgreement = event.target.checked;
        if (event.target.checked) {
            document.getElementById('rules')?.classList.remove('input-error');
        } else {
            document.getElementById('rules')?.classList.add('input-error');
        }

    };

    const back = () => {
        navigation('/companies');
    }

    return (
        <>
            <div className="inner-page">
                <Background>
                    <div className="top-menu absolute">
                        <div onClick={() => {back()}} onTouchStart={() => {back()}}   className="back-btn">
                            <img src={backIcon} alt="backIcon"/>
                            <p>Назад</p>
                        </div>
                    </div>
                    <div className="wrapper">
                        <div className="scan-wrapper">
                            <h1>відскануйте QR запрошення</h1>
                            <div className="scanner">
                                <img src={qrImage} alt="qr"/>
                                <div className="scanner-container">
                                    <Scanner></Scanner>
                                </div>
                            </div>
                            <Rules handleCheckboxChange={handleCheckboxChange}/>
                        </div>
                    </div>
                    <StatusMenu active={[0,1]} />
                </Background>
            </div>
        </>
    );
}
