import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";

const Timer = ({ children }) => {
    const navigate = useNavigate();
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSeconds(prevSeconds => prevSeconds + 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    //Timer in seconds
    useEffect(() => {
        if(seconds > 60){
            navigate('/');
        }
    },[seconds])

    const clearTimer = () => {
        setSeconds(0);
    }

    return <div
        onTouchStart={clearTimer}
        onClick={clearTimer}
        onChange={clearTimer}
        onKeyDown={clearTimer}
    >{children}</div>;
};

export default Timer;
