import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from '@sentry/react';
import './styles/global.css';
import AppProvider from "../src/components/appContext";
import Welcome from "../src/components/welcome/welcome";
import Main from "../src/components/visit/main";
import ScanInvite from "../src/components/scanInvite/scanInvite";
import Visiter from "../src/components/visit/visiter";
import Host from "../src/components/visit/host";
import Photo from "../src/components/visit/photo";
import Finish from "../src/components/visit/finish";
import NotFound from "./components/notFound";
import Companies from "./components/companies/companies";
import RulesText from "./components/rulesText";

Sentry.init({
    dsn: "https://f95a148a511a7157679fa95784ec8b3f@o1230258.ingest.us.sentry.io/4508443213758464",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0, 
    tracePropagationTargets: ["localhost", /^https:\/\/kiosk.visitbook\.io/],
    replaysSessionSampleRate: 0.1, 
    replaysOnErrorSampleRate: 1.0, 
  });

function App() {
    return (
        <Router>
            <AppProvider>
                <ErrorBoundary>
                <Routes>
                    <Route path="/" element={<Welcome/>}/>
                    <Route path="/companies" element={<Companies/>}/>
                    <Route path="/main" element={<Main/>}/>
                    <Route path="/scan-invite" element={<ScanInvite/>}/>
                    <Route path="/visiter" element={<Visiter/>}/>
                    <Route path="/host" element={<Host/>}/>
                    <Route path="/photo" element={<Photo/>}/>
                    <Route path="/finish" element={<Finish/>}/>
                    <Route path="/rules-text" element={<RulesText/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
                </ErrorBoundary>
            </AppProvider>
        </Router>
    );
}

export default App;
