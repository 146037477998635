import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
    const [globalProps, setGlobalProps] = useState({
        company: null,
        visit: {
            id: null,
            visit_hash: null,
            status: null,
            name: null,
            company: "-",
            email: "email@email.com",
            phone: "-",
            company_hash: null,
            visitor_hash: null,
            host_id: null,
            host_name: null
        },
        action: 'create',
        rulesAgreement: true
    });

    const updateGlobalProps = (newProps) => {
        setGlobalProps(newProps);
    };

    return (
        <AppContext.Provider value={{ globalProps, updateGlobalProps }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
