
export default function KeyboardChangeSearch(data:any[], searchText:string) {

    const filteredNormalizedData = data.filter((company:any) => {
        const normalizedCompanyName = normalizeString(company.name);
        const normalizedSearchText = normalizeString(searchText.toLowerCase());
        return normalizedCompanyName.includes(normalizedSearchText);
    });

    function normalizeString(text: string): string {
        const latinToCyrillicMap:{[index: string]:any} = {
            "й": "q", "ц": "w", "у": "e", "к": "r", "е": "t", "н": "y", "г": "u", "ш": "i", "щ": "o", "з": "p",
            "х": "[", "ъ": "]", "ф": "a", "ы": "s", "в": "d", "а": "f", "п": "g", "р": "h", "о": "j", "л": "k",
            "д": "l", "ж": ";", "э": "'", "я": "z", "ч": "x", "с": "c", "м": "v", "и": "b", "т": "n", "ь": "m",
            "б": ",", "ю": "."
        };

        return text
            .toLowerCase()
            .split('')
            .map((char) => {
                if (char in latinToCyrillicMap) {
                    return latinToCyrillicMap[char];
                } else {
                    return char;
                }
            })
            .join('');
    }

    return filteredNormalizedData;

}