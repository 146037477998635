export default function NormalizeSearch(data:any[], searchText:string) {

    const filteredNormalizedData = data.filter((company:any) => {
        const normalizedCompanyName = normalizeString(company.name);
        const normalizedSearchText = normalizeString(searchText.toLowerCase());
        return normalizedCompanyName.includes(normalizedSearchText);
    });

    function normalizeString(text: string): string {
        const latinToCyrillicMap:{[index: string]:any} = {
            'a': 'а', 'b': 'б', 'v': 'в', 'g': 'г', 'd': 'д', 'e': 'е', 'zh': 'ж', 'z': 'з',
            'i': 'и', 'y': 'й', 'k': 'к', 'с': 'к', 'l': 'л', 'm': 'м', 'n': 'н', 'o': 'о', 'p': 'п',
            'r': 'р', 's': 'с', 't': 'т', 'u': 'у', 'f': 'ф', 'h': 'х', 'c': 'ц', 'ch': 'ч',
            'sh': 'ш',  'щ': 'щ', '': 'ъ', 'yu': 'ю', 'ya': 'я'
        };

        return text
            .toLowerCase()
            .split('')
            .map((char) => {
                if (char in latinToCyrillicMap) {
                    return latinToCyrillicMap[char];
                } else {
                    return char;
                }
            })
            .join('');
    }

    return filteredNormalizedData;

}