import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import Background from "../header/Background";
import "./visit.css";
import StatusMenu from "../statusmenu/StatusMenu";
import lastScanImg from "./images/reader.png";
import ticketImg from "./images/ticket.png";
import scanVideo from "./video/scan-video.mp4";
import { useAppContext } from "../appContext";
import Spinner from "../spinner/spinner";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Main() {
  const { globalProps } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [waiting, setWaiting] = useState(true);
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (globalProps.company == null) {
      navigate("/companies");
    }
    if (globalProps.visit.visitor_hash == null) {
      navigate("/companies");
    }

    if (globalProps.action == "create") {
      createVisit();
    } else {
      updateVisit();
    }

    const timeoutId = setTimeout(() => {
      navigate("/companies");
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (globalProps.company.settings.confirm_scenario == 1) {
      const waitTimer = setTimeout(() => {
        setWaiting(false);
      }, 3000);

      return () => clearTimeout(waitTimer);
    }
  }, []);

  // Попередній код без консолей
  // const createVisit = async () => {
  //     try {
  //         axios({
  //             method: 'POST',
  //             url: process.env.REACT_APP_BACKEND_URL + "/apiClient/visit/create" ,
  //             headers: {},
  //             data: globalProps.visit
  //         })
  //         .then((result) => {
  //             axios({
  //                 method: 'POST',
  //                 url: process.env.REACT_APP_BACKEND_URL + "/apiClient/visit/update" ,
  //                 headers: {},
  //                 data: {
  //                     visit_hash: result.data.visit,
  //                     status: (globalProps.company.settings.confirm_scenario == 1 ? 10 : 20)
  //                 }
  //             })
  //             .then((result) => {
  //                 setIsLoading(false)
  //             })
  //         })
  //     } catch (error) {
  //         console.error('Error sending image:', error);
  //     }
  // };

  // З консоллю
  const createVisit = async () => {
    console.error("Виконується функція createVisit...");
    try {
      console.error("Виконання блоку try...");
      console.log("Початок виконання запиту create...");
      console.log(
        "globalProps.visit перед виконанням запиту create:",
        globalProps.visit
      );

      axios({
        method: "POST",
        url: process.env.REACT_APP_BACKEND_URL + "/apiClient/visit/create",
        headers: {},
        data: globalProps.visit,
      }).then((result) => {
        console.log("Блок then. Результат запиту create (result):", result);
        console.log("Результат create: result.data:", result?.data);
        console.log("Початок виконання запиту update...");

        if (result.status === 404) {
          console.log("Помилка 404. Запит create. Показуємо Alert.");
          alert("Візит не створено. Спробуйте ще раз.");
        }

        if (!result.data.visit) {
          console.log(
            `Вихід із функції createVisit та навігація на /companies, оскільки відсутнє значення result.data.visit: ${result.data.visit}...`
          );
          navigate("/companies");
          setIsLoading(false);
          return;
        }

        axios({
          method: "POST",
          url: process.env.REACT_APP_BACKEND_URL + "/apiClient/visit/update",
          headers: {},
          data: {
            visit_hash: result.data.visit,
            status:
              globalProps.company.settings.confirm_scenario == 1 ? 10 : 20,
          },
        }).then((result) => {
          console.log("Блок then. Результат запиту update (result):", result);
          console.log("Результат update: result.data:", result?.data);

          if (result.status === 404) {
            console.log("Помилка 404. Запит update. Показуємо Alert.");
            alert("Візит не створено. Спробуйте ще раз.");
          }

          setIsLoading(false);
        });
      });
    } catch (error) {
      console.error("Виконання блоку catch...");
      console.error("Помилка:", error);
      Sentry.captureException(error);
    }
  };

  const updateVisit = async () => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_BACKEND_URL + "/apiClient/visit/update",
      headers: {},
      data: {
        visit_hash: globalProps.visit.visit_hash,
        status: globalProps.company.settings.confirm_scenario == 1 ? 10 : 20,
      },
    }).then((result) => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Background>
          <div className="wrapper">
            {globalProps.company.settings.confirm_scenario == 1 ? (
              <div className="finish-text-wrap whait">
                {waiting ? (
                  <>
                    <div className="finish-text">
                      <h2>Очікуйте підтвердження та отримайте перпустку</h2>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="scan-steps">
                      <div className="last-scan-step">
                        <video className="scan-video" muted loop autoPlay>
                          <source src={scanVideo} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="finish-text-wrap">
                <div className="finish-text">
                  <div className="scan-steps">
                    <div className="last-scan-step">
                      <video className="scan-video" muted loop autoPlay>
                        <source src={scanVideo} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Background>
      )}
    </>
  );
}
