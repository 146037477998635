import React from 'react';

interface BackgroundProps {
    color?: string;
    image?: string;
    children?: React.ReactNode;
}

export default function Background({ color = '#000', image = './images/main_bg.jpg', children }: BackgroundProps) {
    return (
        <div className="background-container">
            <img
                className="background-image"
                src={image}
                alt="Background Image"
            />
            {children}
        </div>
    );
}
