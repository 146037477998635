import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

interface RulesProps {
    handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Rules ({ handleCheckboxChange }: RulesProps) {
    const navigation = useNavigate();

    const click = () => {
        navigation('/rules-text')
    }

    return (
        <>
            <div className="rules" id="rules">
                <label >
                    <input type="checkbox" defaultChecked onChange={handleCheckboxChange} />
                    <svg viewBox="0 0 64 64" height="2em" width="2em">
                        <path
                            d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                            pathLength="575.0541381835938" className="path"></path>
                    </svg>
                    <p>Я погоджуюсь з <b onClick={click} onTouchStart={click} >умовами </b> політики конфіденційності і дозволяю обробляти мої персональні дані на законних засадах відповідно до умов, зазначених в документі за посиланям. </p>
                </label>
            </div>
        </>
    );
}
