import React, {useEffect, useState} from 'react';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import axios from 'axios';
import ImagePreview from "./imagePreview";
import {useAppContext} from "../appContext";
import {useNavigate} from "react-router-dom";

function WebcamCapture() {
    const navigate = useNavigate();
    const {globalProps} = useAppContext();
    const [dataUri, setDataUri] = useState('');

    function handleTakePhotoAnimationDone(dataUri) {
        console.log(dataUri);
        setDataUri(dataUri);
    }

    useEffect(() => {
        sendImageToServer();
    }, [dataUri]);

    const sendImageToServer = async () => {
        if (!dataUri) {
            return;
        }

        console.log('hash', globalProps.visit.visitor_hash)

        const data = {
            image: dataUri,
            user_hash: globalProps.visit.visitor_hash
        };

        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/apiClient/visitor/photo', data);
            console.log('Image sent successfully:', response.data);
        } catch (error) {
            console.error('Error sending image:', error);
        }
    };

    const handleRetakePhoto = () => {
        setDataUri('');
    };

    const handleCustomButtonClick = () => {
        document.getElementById("outer-circle").click();
    }

    const handleMiss = () => {
        navigate('/finish');
    }

    const isFullscreen = false;
    return (
        <div className="photo-wrapper">
            {
                (dataUri)
                    ? <>
                        <ImagePreview dataUri={dataUri}
                                      isFullscreen={isFullscreen}
                        />
                        <div className="buttons-wrapper">
                            <button
                                type="submit"
                                onClick={handleRetakePhoto}
                                disabled={!dataUri}
                                className="main-btn"
                            >
                                Перефотографувати
                            </button>
                            <button
                                type="submit"
                                className="main-btn"
                                onClick={handleMiss}
                                onTouchStart={handleMiss}
                            >
                                ОК
                            </button>
                        </div>
                    </>
                    : (<>
                        <Camera
                            onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                            isFullscreen={isFullscreen}
                        />
                        <div className="buttons-wrapper">
                            <button
                                type="submit"
                                className="main-btn"
                                onClick={handleCustomButtonClick}
                                onTouchStart={handleCustomButtonClick}
                            >
                                ЗРОБИТИ ФОТО
                            </button>
                            {globalProps.company.settings.photo_is_skippable == 1 ?
                                <button
                                    type="submit"
                                    className="main-btn"
                                    onClick={handleMiss}
                                    onTouchStart={handleMiss}
                                >
                                    Пропустити
                                </button>

                                : ''}
                        </div>
                        </>
                        )
                        }
                        </div>
                    );
            }

export default WebcamCapture;
