import React, {FormEvent, useEffect, useState} from 'react';
import Background from "../header/Background";
import './visit.css';
import avatarImg from './images/user.svg';
import StatusMenu from "../statusmenu/StatusMenu";
import TopMenu from "./TopMenu";
import axios from "axios";
import {useAppContext} from "../appContext";
import {useNavigate} from "react-router-dom";
import Spinner from "../spinner/spinner";
import InputMask from 'react-input-mask';

export default function Visiter() {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const { globalProps } = useAppContext();
    const navigator = useNavigate();
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const response = await axios({
                method: 'get',
                url: process.env.REACT_APP_BACKEND_URL + '/apiClient/visitor/list?company_id=' + globalProps.company.id,
                headers: {},
            });

            if (response.status >= 200 && response.status < 300) {
                setData(response.data);
                // console.log(data);
                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);
            setIsLoading(true);
        }
    };

    const search = (item) => {
        const fullName = item.lastname + ' ' + item.firstname;

        if(name.length > 2){
            if (name.toLowerCase() === fullName.substring(0, name.length).toLowerCase()){
                return true;
            }
        }

        return false;
    }

    useEffect(() => {
        if (globalProps.company == null) {
            navigator('/companies');
        }else{
            fetchData();
        }
    }, []);

    const checkName = (lastname, firstname) => {
        if(globalProps.company.settings.display_name == 1){
            lastname = lastname.substring(0, 1) + (lastname.length > 0 ? '.' : '');
        }

        return lastname + ' ' + firstname;
    }

    /**
     * @param item
     * 'ON-SITE' => 10,
     * 'Check-IN' => 20,
     */
    const handleClickVisit = (item) => {
        setName(item.lastname + ' ' + item.firstname);

        console.log(globalProps.company.hash)
        //console.log(item);

        globalProps.visit = {
            id: item.expectedVisit.id,
            visit_hash: item.expectedVisit.hash,
            status: (globalProps.company.settings.confirm_scenario == 1 ? 'ON-SITE' : 'Check-IN'),
            name: item.lastname + ' ' + item.firstname ,
            company: item.companyName,
            email: item.email,
            phone: item.phone,
            company_hash: globalProps.company.hash,
            visitor_hash: item.hash,
            host_id: item.expectedVisit.hostId,
            host_name: item.expectedVisit.hostName
        };

        globalProps.action = 'update';

        if('COMPANY_SETTING_IS_PHOTOBLE' in globalProps.company.settings){
            if(globalProps.company.settings.COMPANY_SETTING_IS_PHOTOBLE == 1){
                navigate('/photo');
            }else{
                navigate('/finish');
            }
        }else {
            navigate('/finish');
        }

    }

    return (
        <>
            {isLoading ? (<Spinner />) : (
            <div className="inner-page">
                <Background>
                       <TopMenu link={'/main'} />
                    <div className="wrapper">
                            <input
                                type="text"
                                id="name"
                                className="search"
                                placeholder="Введіть ваше прізвище"
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                            />
                        <div className="user-wrap">
                            {data.map((item, key) => {
                                if (!search(item)) { return null; }
                                if (item.expectedVisit == null) { return null; }
                                return (
                                    <div key={item.id} className="user" onClick={() => {handleClickVisit(item)}} onTouchStart={() => {handleClickVisit(item)}}>
                                        <div className="user__info">
                                            <img src={avatarImg} alt="avatar" />
                                            <p>{checkName(item.lastname, item.firstname)}</p>
                                        </div>
                                        <div className={item.expectedVisit == null ? "user__status" : "user__status waiting"}> Збережений відвідувач </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <StatusMenu active={[0,1]} />
                </Background>
            </div>
            )}
        </>
    );
}
