import React from "react";
import Timer from "./timer";
import Background from "./header/Background";
import TopMenu from "./visit/TopMenu";
import CloseIcon from "./visit/images/closeIcon.svg";
import {useNavigate} from "react-router-dom";

export default function RulesText(){

    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/main');
    }

    return (
        <>
            <Timer>
                <div className="inner-page">
                    <Background>
                        <TopMenu link={'/main'} />
                        <div className="card-rules">
                            <img className="close-icon" src={CloseIcon} alt="close" onClick={handleClick} onTouchStart={handleClick}/>
                            <div className="card rules-text">
                                <h1 className="like-h5">Правила та умови перебування відвідувачів на
                                території ТРЦ «Гулівер»</h1>
                                <div><strong>1. Визначення термінів</strong></div>
                                <div><strong>Адміністрація</strong>&nbsp;– належним чином уповноважені представники та
                                    працівники власника ТОК (ТОВ «ТРИ О»), які діють від його імені та в його інтересах;
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>Будівля</strong>&nbsp;– будівля торгово-офісного комплексу (ТОК), що
                                    знаходиться&nbsp; за&nbsp; адресою:&nbsp; &nbsp; &nbsp; &nbsp;</div>
                                <div>м. Київ, площа Спортивна, 1-А.</div>
                                <div>&nbsp;</div>
                                <div><strong>ТОК</strong>&nbsp;– торгово-офісний комплекс, що включає в себе
                                    бізнес-центр, торгово-розважальний центр, паркінг та знаходиться за адресом: м.
                                    Київ, площа Спортивна, 1-А.
                                </div>
                                <div><strong>БЦ</strong>&nbsp; – Бізнес-центр, який включає в себе
                                    тридцятип’ятиповерхову частину будівлі ТОК (частина будівлі (Вежа А) зі сторони
                                    вулиці Басейної, а також офісну частину (Вежа Б) на поверхах з дев’ятого по
                                    чотирнадцятий).
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>Відвідувач</strong>&nbsp;– будь-яка фізична особа, що перебуває на
                                    території ТОК.
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>Власник Будівлі</strong>&nbsp;- Товариство з обмеженою відповідальністю
                                    «ТРИ О», ідентифікаційний код юридичної особи: 23167814, що знаходиться за адресою:
                                    01001, місто Київ, Печерський район, площа Спортивна, 1-А.
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>Години роботи ТРЦ/БЦ</strong>&nbsp;– години активної роботи ТРЦ/БЦ, коли
                                    дозволений доступ перебування Відвідувачів на території ТРЦ чи БЦ.&nbsp;</div>
                                <div>&nbsp;</div>
                                <div><strong>Загальні площі</strong>&nbsp;- приміщення, площі і території
                                    Торгового-розважального центру, відведені Власником Будівлі для загального
                                    користування Відвідувачами з розташованими на них механічними пристроями,
                                    коридорами, сходовими майданчиками, сходами, холами, ліфтами, ескалаторами,
                                    траволаторами, коридорами, підвалами, пожежними виходами, розташованими в Будівлі, а
                                    також автомобільними стоянками, під'їзними шляхами, дорогами, тротуарами,
                                    господарським двором, територіями для збору і вивезення сміття, елементами
                                    озеленення і благоустрою, і іншими об'єктами розташованими на території ТРЦ.
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>Зони обмеженого доступу</strong>&nbsp;- приміщення, площі і території ТРЦ,
                                    доступ і перебування на яких є обмеженими і здійснюються у порядку, встановленому
                                    Адміністрацією.
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>Паркінг</strong>&nbsp;- сукупність спеціально облаштованих та обладнаних
                                    паркувальних місць, розташованих в Будівлі і призначених для організованої
                                    тимчасової стоянки транспортних засобів Відвідувачів та Орендарів.
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>Правила</strong>&nbsp;– дійсні Правила та умови перебування відвідувачів на
                                    території ТРЦ зі всіма наступними змінами та доповненнями.
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>Селфі&nbsp;</strong>- вид фотографії, автопортрет, зроблений за допомогою
                                    камери смартфону, фотоапарата чи веб-камери. Може бути зроблений фотографуванням як
                                    власного вигляду так і його відображення у дзеркалі.&nbsp;</div>
                                <div>&nbsp;</div>
                                <div><strong>Служба охорони</strong>&nbsp;- організація, залучена Власником Будівлі для
                                    охорони ТОК.
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>ТРЦ</strong>&nbsp;– торгово-розважальний центр, що розміщений&nbsp; у
                                    чотирнадцятиповерховій частині будівлі ТОК (частина будівлі зі сторони площі
                                    Спортивної). На території ТРЦ розміщені торгова галерея, ресторани та кафе,
                                    кінотеатр, боулінг, дитяча зона, супермаркет.
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>Укриття</strong>&nbsp;– визначена Адміністрацією на час воєнного стану
                                    частина ТОК спеціально обладнана для тимчасового перебування людей під час сигналу
                                    «Повітряна тривога» з метою захисту від небезпечних чинників (у т.ч. засобів
                                    ураження).&nbsp;</div>
                                <div>&nbsp;</div>
                                <div><strong>Фуд-корт</strong>&nbsp;- зона харчування в ТРЦ, де відвідувачам пропонують
                                    послуги відразу кілька закладів харчування, що мають загальний зал для харчування.
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>2. Загальні положення</strong></div>
                                <div>2.1 Ці Правила містять правила поведінки Відвідувачів в Торгово-розважальному
                                    центрі і користування послугами ТРЦ.
                                </div>
                                <div>2.2 Ці Правила розроблені відповідно до чинного законодавства України, у т.ч.
                                    Цивільного Кодексу України, Закону про захист прав споживачів, Закону про
                                    торговельну діяльність, Закону України про пожежну безпеку, Закону України про
                                    боротьбу з тероризмом.
                                </div>
                                <div>2.3 Ці Правила розроблені Адміністрацією у порядку реалізації своїх повноважень та
                                    повноважень Власника ТОК. Адміністрація самостійно визначає зміст цих Правил з
                                    дотриманням принципів недоторканості приватної власності та недоторканості фізичної
                                    особи, свободи договору, передбачених законодавством України.
                                </div>
                                <div>2.4 Ці Правила встановлені з метою забезпечення умов належного функціонування і
                                    експлуатації ТРЦ, дотримання прав і законних інтересів Власника Будівлі й
                                    Відвідувачів ТРЦ, включаючи орендарів приміщень, площ, територій ТРЦ.
                                </div>
                                <div>2.5 Власник ТОК, а також призначена ним Адміністрація та (або) залучена ним Служба
                                    охорони не несуть відповідальності за будь-яку шкоду, яка може бути заподіяна в ТРЦ
                                    не з їх вини майну будь-яких осіб, життю і здоров'ю Відвідувачів, в тому числі
                                    заподіяну діями (бездіяльністю) орендарів приміщень і (або) третіх осіб, включаючи
                                    дії (бездіяльність) будь-яких Відвідувачів, а також не несе відповідальності за
                                    збереження будь-якого майна (речей, документів, грошових засобів і т.д.) будь-яких
                                    осіб, що знаходиться в ТРЦ, включаючи Відвідувачів.
                                </div>
                                <div>2.6 У разі порушення Відвідувачами цих Правил ні Власник Будівлі, ні Адміністрація,
                                    ні Служба охорони не несуть відповідальності за шкоду, заподіяну такими діями
                                    (бездіяльністю) життю або здоров'ю Відвідувачів, а також не несуть відповідальності
                                    за завдані їм у зв'язку з цим збитки.
                                </div>
                                <div>2.7 Періодично Адміністрацією ТОК проводяться масові заходи з метою популяризації
                                    ТРЦ із здійсненням фото- та відео- фіксації таких заходів. Перебуванням у ТРЦ
                                    Відвідувач надає свою згоду на фотографування та відеозйомку як його особисто, так і
                                    неповнолітніх/малолітніх членів його сім’ї, а також його тварин, в рамках таких
                                    заходів.&nbsp;&nbsp;</div>
                                <div>Перебуванням у ТРЦ Відвідувач також надає безстрокову згоду на безоплатне
                                    використання знятих у ТРЦ фото- та відеоматеріалів, які містять його зображення
                                    та/або зображення неповнолітніх/малолітніх членів його сім’ї, при створенні реклами,
                                    інформаційних повідомлень про діяльність ТРЦ та в інших цілях, пов’язаних із
                                    діяльністю ТРЦ, а також надає згоду на зберігання, тиражування, розповсюдження та
                                    переробку вказаних фото- та відеоматеріалів.&nbsp; &nbsp;</div>
                                <div>2.8 Ці Правила є обов'язковими для виконання і поширюються на всіх Відвідувачів,
                                    якщо інше не буде встановлено Адміністрацією і (або) угодою Адміністрації з
                                    відповідною особою.
                                </div>
                                <div>2.9 Адміністрація вправі в будь-який час в односторонньому порядку вносити зміни і
                                    (або) доповнення до чинних Правил, в тому числі в додатки до них. Зазначені зміни
                                    вступають в силу з дати їх розміщення на інформаційних стендах (вивісках),
                                    інфостійках ТРЦ або на офіційному сайті ТРЦ в мережі Інтернет за адресою
                                    https://gullivercenter.com/trc-siterules .
                                </div>
                                <div>2.10 Всі майнові авторські права на дизайн інтер’єру та екстер’єру ТРЦ (включаючи
                                    розташування елементів декору, концепцію освітлення ТРЦ, планування ТРЦ тощо) як
                                    об’єкт інтелектуальної власності (твір архітектури) належать Власнику Будівлі.
                                    Створенням фотографій та/або відеозаписів, на яких зображена будівля ТОК чи її
                                    частина (як всередині, так і зовні будівлі), Відвідувач надає свою повну та
                                    безумовну згоду Власнику Будівлі на використання таких фотографій та/або
                                    відеозаписів, а також будь-яких інших творів, створених з їх використанням, якщо
                                    вони були розміщені в мережі Інтернет у вільному доступі. Зазначені фотографії
                                    та/або відеозаписи та створені з їх використанням твори можуть вільно
                                    використовуватися Власником Будівлі з посиланням на джерело їх
                                    публікації.&nbsp;</div>
                                <div>2.11 Заборонено створення та використання фото-, відео-, або інших творів, на яких
                                    зображена будівля ТОК чи її частина (як всередині, так і зовні будівлі), окрім
                                    випадків, коли такі дії погоджено з Адміністрацією ТОК.
                                </div>
                                <div>2.12 Факт перебування в ТРЦ означає, що Відвідувач погоджується з цими Правилами в
                                    повному обсязі (включаючи додатки до них, зі змінами та доповненнями, що вносяться в
                                    дані документи), приймає їх і зобов'язується неухильно дотримуватися. У разі незгоди
                                    повністю або в якій-небудь частині з цими Правилами, Відвідувач повинен залишити ТРЦ
                                    або відмовитися від наміру відвідати Торгово-розважальний центр.
                                </div>
                                <div>2.13 Терміни та визначення, значення яких дано в розділі 1 цих Правил,
                                    застосовуються до всього тексту Правил (в тому числі до додатків, що є невід'ємною
                                    частиною цих Правил), якщо інші значення таких термінів і визначень не передбачені в
                                    тексті самих додатків.
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>3. Години роботи ТРЦ та закладів, що є його невід’ємною
                                    частиною&nbsp;</strong></div>
                                <div>3.1. Інформація про Години роботи підлягає розміщенню на інформаційних стендах
                                    (вивісках) ТРЦ, а також на офіційному сайті ТРЦ в мережі Інтернет за адресою :
                                    https://gullivercenter.com.
                                </div>
                                <div>&nbsp;</div>
                                <div>3.2. Адміністрація має право:</div>
                                <div>- Встановлювати та змінювати Години роботи ТРЦ:&nbsp;</div>
                                <div>- закривати ТРЦ або обмежувати доступ в ТРЦ з метою забезпечення
                                    пожежної,&nbsp; громадської безпеки, безпеки при надзвичайній ситуації, а також за
                                    погодних умов, з технологічних, технічних, експлуатаційних, санітарно-гігієнічних,
                                    організаційних і інших причин, що створюють загрозу життю або здоров'ю людей або
                                    заподіяння шкоди майну.
                                </div>
                                <div>&nbsp;</div>
                                <div>&nbsp;</div>
                                <div><strong>4. Система оповіщення в ТРЦ</strong></div>
                                <div>4.1. ТРЦ обладнаний системою місцевого оповіщення (гучного зв'язку).&nbsp;</div>
                                <div>4.2. Передавати будь-які повідомлення по системі місцевого оповіщення має право
                                    тільки черговий адміністратор ТРЦ або черговий диспетчер за вказівкою Адміністрації.
                                </div>
                                <div>4.3. По системі оповіщення передаються повідомлення:</div>
                                <div>- про пошук власників автотранспортних засобів, припаркованих з
                                    порушенням&nbsp; Правил користування Паркінгом;
                                </div>
                                <div>- пов'язані з безпекою Відвідувачів ТРЦ, а саме: про необхідність негайної
                                    евакуації, про загрозу здійснення терористичного акту, інші термінові повідомлення
                                    щодо безпеки відвідувачів.
                                </div>
                                <div>4.4. За системою оповіщення можуть передаватися рекламні повідомлення про
                                    діяльність орендарів приміщень, площ, територій ТРЦ, що здійснюють продаж товарів,
                                    виконання робіт і (або) надання послуг. Зміст і частота повторення таких рекламних
                                    повідомлень підлягають письмовому узгодженню з Адміністрацією.
                                </div>
                                <div>4.5. В інший час, коли ТРЦ відкритий для вільного доступу Відвідувачів, і при
                                    відсутності необхідності передачі вищевказаних повідомлень, по системі оповіщення
                                    можуть передаватися спокійні музичні твори.
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>5. Служба охорони&nbsp;</strong></div>
                                <div>Служба охорони забезпечує щоденну цілодобову охорону Загальних площ ТРЦ.</div>
                                <div>5.1 Працівник Служби охорони має право не допускати в ТРЦ і, при необхідності,
                                    випроводжати з ТРЦ:
                                </div>
                                <div>- осіб з явними ознаками алкогольного, наркотичного або іншого токсичного
                                    сп'яніння;
                                </div>
                                <div>- осіб, які порушують громадський порядок і виявляють в явній і грубій формі
                                    неповагу до оточуючих;
                                </div>
                                <div>- осіб в брудному одязі, осіб, від яких чути неприємний запах;</div>
                                <div>- осіб, що перебувають в ТРЦ без одягу, з голим торсом, або вигляд яких ображає
                                    людську гідність та явно&nbsp; несумісний із загальноприйнятими нормами суспільної
                                    поведінки;&nbsp;</div>
                                <div>- осіб, які порушують ці Правила;</div>
                                <div>- осіб, котрі раніше грубо або неодноразово порушили вимоги цих Правил, включаючи
                                    додатки до них;
                                </div>
                                <div>- осіб, які ведуть фото- і відеозйомку з порушенням вимог, передбачених у п.7.5.6.
                                    цих Правил.
                                </div>
                                <div>5.2 При необхідності працівники Служби охорони можуть звертатися та залучати
                                    чергові пости поліції і (або) інші територіальні підрозділи органів внутрішніх
                                    справ.
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>6. Доступ в Торгово-розважальний центр</strong></div>
                                <div>6.1 Надання або обмеження доступу Відвідувачам у ТРЦ здійснюється Адміністрацією і
                                    (або) Службою охорони, які діють відповідно до вказівок та інтересів Власника
                                    Будівлі.&nbsp;</div>
                                <div>Доступ на територію ТРЦ надається усім бажаючим тільки в Години роботи ТРЦ, та за
                                    умови дотримання цих Правил та виконання законних вимог Адміністрації та (або)
                                    Служби охорони.&nbsp;</div>
                                <div>Доступ у ТРЦ в цілому або в його окремі частини може бути обмежений
                                    Адміністрацією.&nbsp;</div>
                                <div>&nbsp;</div>
                                <div>6.2 Доступ у ТРЦ зі зброєю можливий тільки для:</div>
                                <div>- працівників правоохоронних органів, які мають право на носіння зброї в рамках
                                    реалізації повноважень, передбачених законодавством України;
                                </div>
                                <div>- співробітників служб інкасації, які мають право на носіння зброї в рамках
                                    реалізації повноважень, передбачених законодавством України.&nbsp;</div>
                                <div>&nbsp;</div>
                                <div>6.3 Відвідувач, якому відмовлено в доступі у ТРЦ, зобов'язаний негайно залишити ТРЦ
                                    і не робити після цього спроб неправомірного проникнення в ТРЦ.&nbsp;</div>
                                <div>&nbsp;</div>
                                <div>6.4 Журналісти для здійснення своєї професійної діяльності на території ТРЦ, інші
                                    особи, які бажають здійснювати фото- відеозйомку, висвітлювати події, готувати фото-
                                    відеосюжети на території ТРЦ подають заявки на адресу Адміністрації для отримання
                                    відповідного дозволу. Оформивши необхідний дозвіл журналісти, інші особи, у
                                    відповідності до вказаної у заявці мети, можуть здійснювати відповідну діяльність у
                                    погодженому місці ТРЦ.
                                </div>
                                <div>&nbsp;</div>
                                <div>Охоронник має право попросити показати дозвіл від Адміністрації на здійснення
                                    зазначеної вище діяльності на території ТРЦ. При відсутності даного дозволу
                                    охоронник повідомляє особу про заборону здійснення такої діяльності у ТОК без
                                    дозволу Адміністрації та необхідність припинити діяльність, крім випадків, коли
                                    здійснення такої діяльності прямо дозволено законом.&nbsp;</div>
                                <div>&nbsp;</div>
                                <div><strong>7. Перебування&nbsp; в Торгово-розважальному центрі</strong></div>
                                <div>7.1 За умови дотримання цих Правил та виконання законних вимог Адміністрації та
                                    (або) Служби охорони кожен Відвідувач має можливість перебувати в ТРЦ в Години
                                    роботи ТРЦ.
                                </div>
                                <div>7.2 Знаходження на території ТРЦ в інший час, відмінний від встановлених
                                    Адміністрацією Годин роботи ТРЦ, можливо виключно з попередньої письмової згоди
                                    Адміністрації.
                                </div>
                                <div>7.3 Відвідувачі повинні завчасно залишати ТРЦ до закінчення Годин роботи (окрім
                                    закладів з окремим графіком роботи (ресторани, боулінг, кінотеатр і т.п).
                                </div>
                                <div>7.4 Перебування у ТРЦ дітей до 12 років дозволено тільки у супроводі дорослих.
                                    Служба охорони має право не допустити на територію ТРЦ&nbsp; дітей молодших 12 років
                                    без супроводу дорослих.
                                </div>
                                <div>7.5 При знаходженні в ТРЦ Відвідувачам забороняється:</div>
                                <div>7.5.1 вчиняти дії (бездіяльність), які загрожують життю або здоров'ю громадян,
                                    можуть завдати шкоди ТРЦ і (або) шкоду майну Власника Будівлі й Відвідувачів,
                                    включаючи орендарів приміщень;
                                </div>
                                <div>7.5.2 проносити в ТРЦ холодну, вогнепальну, газову зброю та боєприпаси, вибухові
                                    речовини і вибухові пристрої, електрошокові засоби, отруйні, радіоактивні,
                                    вогненебезпечні, піротехнічні, легкозаймисті речовини, механічні або автоматичні
                                    аерозольні розпилювачі всіх видів;
                                </div>
                                <div>7.5.3 без погодження з Адміністрацією здійснювати на території ТРЦ торгівлю, інші
                                    види підприємницької діяльності;&nbsp;</div>
                                <div>7.5.4 без погодження з Адміністрацією здійснювати на території ТРЦ комерційну та
                                    маркетингову активність, розміщувати рекламу, проводити масові заходи, здійснювати
                                    збір коштів, благодійницьку діяльність, тощо;
                                </div>
                                <div>7.5.5 проводити на території ТРЦ будь-яку політичну агітацію, розповсюджувати
                                    друковані матеріали, а також здійснювати інші дії, що пов’язані із політичною
                                    активністю;&nbsp;</div>
                                <div>7.5.6 без погодження з Адміністрацією здійснювати фото- та відеозйомку, за винятком
                                    «Селфі» і фотознімків в особистих цілях, які здійснюються з використанням мобільних
                                    телефонів і (або) аматорських фотоапаратів або відеокамер, якщо при цьому особа, що
                                    здійснює фото- і відеозйомку, своїми діями не створює незручності іншим Відвідувачам
                                    ТРЦ і не порушує громадський порядок;&nbsp;</div>
                                <div>7.5.7 без погодження з Адміністрацією грати в ТРЦ на музичних інструментах,
                                    використовувати звуко- та відеовідтворюючі пристрої (окрім персональних);
                                </div>
                                <div>7.5.8 розмовляти на підвищених тонах, вживати нецензурні вирази, вчиняти дії, що
                                    ображають честь і гідність інших Відвідувачів;
                                </div>
                                <div>7.5.9 включати та слухати голосну музику;</div>
                                <div>7.5.10 залишати сумки, речі та інші предмети без нагляду;</div>
                                <div>7.5.11 залишати дітей без нагляду;</div>
                                <div>7.5.12 годувати і сповивати дітей в місцях, не відведених для зазначених цілей;
                                </div>
                                <div>7.5.13 приймати їжу поза зонами фуд-кортів, ресторанів; переносити територією ТРЦ
                                    (у т.ч. у ліфтах та на ескалаторах) гарячу їжу та напої (крім перенесення у
                                    герметично закритій тарі/упаковці);
                                </div>
                                <div>7.5.14 викидати сміття в непередбачених для цього місцях;</div>
                                <div>7.5.15 заходити за огородження місць проведення ремонтних або будь-яких інших видів
                                    робіт;
                                </div>
                                <div>7.5.16 робити спроби неправомірного проникнення і (або) знаходження в Зоні
                                    обмеженого доступу;
                                </div>
                                <div>7.5.17 заходити у ТРЦ в стані алкогольного та/або наркотичного сп’яніння, а також
                                    мати при собі наркотичні, психотропні та інші небезпечні речовини
                                </div>
                                <div>7.5.18 вживати наркотичні та психотропні речовини;</div>
                                <div>7.5.19 курити (включаючи, але не обмежуючись: кальяни, вейпери, курильні суміші,
                                    електронні сигарети), окрім як у спеціально відведених місцях;
                                </div>
                                <div>7.5.20 вживати пиво, інші алкогольні напої, за винятком таких, що реалізуються в
                                    ресторанах, кафе та інших пунктах харчування ТРЦ на підставі відповідного дозволу та
                                    підлягають вживанню виключно в таких пунктах харчування;
                                </div>
                                <div>7.5.21 перебувати без одягу, з голим торсом або з зовнішнім виглядом, що виражає
                                    явну неповагу до оточуючих, який ображає людську гідність і суспільну мораль;
                                </div>
                                <div>7.5.22 займатися жебрацтвом;</div>
                                <div>7.5.23 зупиняти або блокувати автоматичні карусельні двері, затримувати вхід/вихід
                                    інших відвідувачів, заходити в один відсік більше трьох осіб;
                                </div>
                                <div>7.5.24 бігати, бігати по ескалаторах, рухатись по ескалатору проти руху, стрибати,
                                    підніматися на огорожі, перила, технічні споруди, зістрибувати з будь-якої висоти,
                                    перехилятись через перила, заходити в Зони обмеженого доступу, в тому числі у
                                    службові приміщення, а також інші Зони обмеженого доступу, оформлені відповідними
                                    інформаційними табло, знаками, покажчиками;
                                </div>
                                <div>7.5.25 сидіти на сходах, підлогах, огорожах і тротуарах;</div>
                                <div>7.5.26 заходити в туалетні кімнати під час проведення там прибирання;</div>
                                <div>7.5.27 використовувати санвузли не за цільовим призначенням;</div>
                                <div>7.5.28 чіпати, переміщати інвентар, прикраси, ялинкові іграшки, елементи декору
                                    ТРЦ, будь - яке електричне обладнання, яке не призначене для ігор і розваг, що
                                    проводяться в ТРЦ з дозволу Адміністрації;
                                </div>
                                <div>7.5.29 заходити до ТРЦ із великогабаритними предметами, до яких відносяться
                                    предмети, що по сумі трьох вимірів перевищують 180 см, крім товарів, придбаних в
                                    закладах торгівлі на території ТРЦ;
                                </div>
                                <div>7.5.30 кататися на велосипедах, роликових ковзанах, скейтбордах, електроскейтах,
                                    самокатах, гіроскутерах та інших засобах пересування;
                                </div>
                                <div>7.5.31 використовувати радіокеровану техніку (машини, вертольоти, квадрокоптери
                                    тощо);
                                </div>
                                <div>7.5.32 розводити багаття, запалювати свічки або іншим чином використовувати
                                    відкритий вогонь;
                                </div>
                                <div>7.5.33 перебувати у ТРЦ з тваринами, окрім випадків:&nbsp;</div>
                                <div>а) коли це дозволено законодавством України (використання
                                    собак-поводирів,&nbsp; &nbsp; тощо); б) перебування в ТРЦ з декоративними та
                                    кишеньковими собаками, що знаходяться на руках та/або в сумці власника;&nbsp;</div>
                                <div>в) перебування в ТРЦ з іншими собаками, що утримуються власником на повідку та у
                                    відповідному наморднику. При цьому, у випадках, зазначених в даному пункті Правил,
                                    перебування в ТРЦ з тваринами можливе виключно за умови дотримання власником
                                    гігієнічних норм перебування тварин у громадських місцях, в тому числі самостійного
                                    негайного прибирання продуктів життєдіяльності тварин;
                                </div>
                                <div>7.5.34 продавати і (або) демонструвати порнографічну продукцію, продукцію
                                    екстремістського змісту;&nbsp;</div>
                                <div>7.5.35 організовувати та проводити на території ТОК азартні ігри та брати у них
                                    участь.
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>8. Права відвідувачів</strong></div>
                                <div>Відвідувач має право:</div>
                                <div>8.1 Будучи споживачем в порядку, передбаченому Законом України «Про захист прав
                                    споживачів», отримувати від орендарів приміщень, площ і територій ТРЦ, які
                                    здійснюють діяльність з продажу товарів, виконання робіт (надання послуг), повну і
                                    достовірну інформацію про роботу магазинів, про реалізовувані ними товари
                                    та&nbsp; послуги, тощо.&nbsp;</div>
                                <div>8.2 Відвідувачі з обмеженими фізичними можливостями можуть безперешкодно
                                    пересуватись по всій загальнодоступній території ТРЦ, в тому числі
                                    підніматись/опускатись ліфтами.
                                </div>
                                <div>8.3 Здійснювати фото- і відео- зйомку для особистих цілей, не пов'язаних з
                                    комерційними або іншими професійними цілями, в тому числі «Селфі».
                                </div>
                                <div>8.4 При перебуванні у Торгово-розважальному центрі:</div>
                                <div>8.4.1 відвідувати ресторани, фуд-корти за умови дотримання Правил відвідування
                                    фуд-кортів (Додаток № 3 до цих Правил).
                                </div>
                                <div>8.4.2 користуватися пасажирськими ліфтами за умови дотримання Правил користування
                                    пасажирським ліфтом (Додаток № 4 до цих Правил).
                                </div>
                                <div>8.4.3 користуватися ескалаторами і траволаторами за умови дотримання Правил
                                    користування ескалатором і траволатором (Додаток № 5 до цих Правил).
                                </div>
                                <div>8.4.4 відвідувати кімнату матері і дитини за умови дотримання Правил відвідування
                                    кімнати матері і дитини (Додаток № 6 до цих Правил).
                                </div>
                                <div>8.4.5 під час повітряної тривоги користуватися Укриттям з дотриманням Правил
                                    перебуванні в Укритті (додаток №7 до цих Правил).
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>9. Обов’язки відвідувачів</strong></div>
                                <div>Відвідувач зобов’язаний:</div>
                                <div>9.1. перед відвідуванням ТРЦ самостійно і детально ознайомитися з цими Правилами,
                                    які розміщені на офіційному сайті за адресою: https://gullivercenter.com/trc, а
                                    також з іншими інформаційними матеріалами, розміщеними на інформаційних стендах
                                    (вивісках), інфостійках ТРЦ або в мережі Інтернет на офіційному сайті за адресою:
                                    https://gullivercenter.com/trc;&nbsp;</div>
                                <div>9.2. дотримуватися цих Правил, включаючи додатки до них, а також зміни і (або)
                                    доповнення до цих Правил;
                                </div>
                                <div>9.3. дотримуватися відповідних вимог пожежної, громадської, санітарної безпеки і
                                    інші вимоги, передбачені законодавством України в сфері забезпечення безпеки;
                                </div>
                                <div>9.4 поводитися шанобливо по відношенню до оточуючих;</div>
                                <div>9.5 дбайливо ставитися до майна Власника Будівлі й інших Відвідувачів, в тому числі
                                    орендарів ТРЦ, а також іншого майна на території ТРЦ;
                                </div>
                                <div>9.6 використовувати виключно за призначенням санітарно-технічне обладнання і
                                    фурнітуру, встановлені Власником Будівлі в громадських туалетах і інших місцях
                                    загального користування;
                                </div>
                                <div>9.7 виявляти розумну обережність під час руху&nbsp; по мокрій підлозі та інших
                                    покриттях;
                                </div>
                                <div>9.8 шанобливо ставитися до представників Адміністрації та Служби охорони,
                                    виконувати їх законні вказівки та вимоги;
                                </div>
                                <div>9.9 негайно повідомляти будь-кому з представників Адміністрації, Служби охорони та
                                    інших співробітників ТРЦ про виявлення задимлення або пожежі, а також про випадки
                                    виявлення підозрілих предметів, речей;
                                </div>
                                <div>9.10 повідомляти співробітника Служби охорони про наявність при собі предметів,
                                    заносити які в ТРЦ забороняється;
                                </div>
                                <div>9.11 при недотриманні цих Правил покинути ТРЦ за вказівкою співробітника
                                    Адміністрації та (або) Служби охорони;
                                </div>
                                <div>9.12 паркувати транспортні засоби на Паркінгу відповідно до Правил користування
                                    паркінгом, які є Додатком № 2 до цих Правил;
                                </div>
                                <div>9.13 негайно повідомляти будь-кому із представників Адміністрації, Служби охорони
                                    та інших співробітників ТРЦ про будь-які випадки порушення цих Правил та чинного
                                    законодавства України Відвідувачем або групою Відвідувачів.
                                </div>
                                <div>9.14 Дотримуватися запроваджених відповідно до законодавства обмежувальних
                                    протиепідемічних заходів, у т.ч. обмежувальних заходів запроваджених
                                    Адміністрацією.&nbsp;</div>
                                <div>9.15 Дотримуватися правил та обмежень, запроваджених у зв’язку із воєнним станом.
                                </div>
                                <div>&nbsp;</div>
                                <div>&nbsp;</div>
                                <div><strong>10 Відповідальність за порушення правил ТРЦ</strong></div>
                                <div>10.1. Відвідувачі, що порушили вимоги цих Правил, в результаті чого їх діями
                                    (бездіяльністю) було заподіяно майнову шкоду ТРЦ, Власнику Будівлі й (або) іншим
                                    Відвідувачам, можуть бути притягнуті до цивільної відповідальності з метою
                                    відшкодування завданих збитків в порядку, встановленому чинним законодавством
                                    України.
                                </div>
                                <div>10.2. Відвідувачі, що порушили вимоги цих Правил та в діях яких вбачаються ознаки
                                    адміністративного правопорушення чи кримінального злочину, можуть бути залучені,
                                    відповідно, до адміністративної або кримінальної відповідальності відповідно до
                                    чинного законодавства України.
                                </div>
                                <div>10.3. Адміністрація має право вживати всі законні заходи, спрямовані на залучення
                                    до відповідної цивільно-правової (матеріальної), адміністративної та (або)
                                    кримінальної відповідальності винних осіб.
                                </div>
                                <div>10.4. За фактом порушення цих Правил, які мали місце на території ТРЦ, представники
                                    Адміністрації можуть складати акт за участю представників Служби охорони та інших
                                    осіб (за рішенням Адміністрації).
                                </div>
                                <div>10.5. Вищевказаний акт не є правовим актом індивідуального застосування, є
                                    документом фіксуючого характеру.
                                </div>
                                <div>10.6. Складені представниками Адміністрації акти зберігаються у Адміністрації.
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>11 Довідка ТРЦ&nbsp;</strong></div>
                                <div>&nbsp;</div>
                                <div>11.1. Схема та назви розміщених в ТРЦ магазинів, пунктів харчування і т.п., їх
                                    розташування, відомості про пропоновані ними товари, роботи&nbsp; і
                                    послуги&nbsp; публікуються на інформаційних стендах, тач-панелях (сенсорних
                                    екранах), на офіційному сайті ТОК «Гулівер», в мережі Інтернет за адресою
                                    https://gullivercenter.com/trc.
                                </div>
                                <div>&nbsp;</div>
                                <div><strong>12 Загублені речі&nbsp;</strong></div>
                                <div>12.1. У разі втрати або знаходження (виявлення) будь-якого предмета, залишеного
                                    невідомою особою, Відвідувачу необхідно звернутися до співробітників Служби охорони
                                    або Адміністраторів ТРЦ на Інфостійку&nbsp; або зателефонувати за тел.:
                                    +380(98)555-55-09 в Години роботи ТРЦ.
                                </div>
                                <div>12.2. Співробітник Служби охорони має право розкрити і оглянути вміст знайдених
                                    предметів або запросити співробітників поліції для зазначених цілей.
                                </div>
                                <div>12.3. Адміністратор повертає загублену річ Відвідувачу при умові попереднього
                                    отримання від Відвідувача підтвердження належності речі Відповідачу (надання
                                    відповідних документів, правильний&nbsp; опис предмета чи його вмісту, тощо). До
                                    передачі предмета його власнику складається документ, де фіксується така передача, в
                                    якому обов'язково вказуються паспортні дані Відвідувача.
                                </div>
                                <div>12.4. Якщо ніхто не з’являється за знайденим предметом протягом двох місяців,
                                    Адміністрація залишає за собою право розпорядитися ним на свій розсуд, в тому числі
                                    передати його на утилізацію.
                                </div>
                            </div>
                        </div>
                    </Background>
                </div>
            </Timer>
        </>
    );
}


